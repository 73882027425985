import { AccordionPlugin } from '../../src/accordion/scripts/Accordion';
import { BackLinkPlugin } from '../../src/common/scripts/BackLink';
import { CorporateBasePage } from '../../src/layout/scripts/basePages/CorporateBasePage';
import { CmsFormPlugin } from '../../src/form/scripts/CmsForm';
import { CorporateCookieConsentPlugin } from '../../src/cookie-consent/scripts/CorporateCookieConsent';
import { CountrySelectorPlugin } from '../../src/country-selector/scripts/CountrySelector';
import { DateFormatterPlugin } from '../../src/common/scripts/DateFormatter';
import { DropdownFilterPlugin } from '../../src/filters/scripts/DropdownFilter';
import { DropdownPlugin } from '../../src/common/scripts/Dropdown';
import { EventListingPlugin } from '../../src/events/scripts/EventListing';
import { EventsGridPlugin } from '../../src/events/scripts/EventsGrid';
import { HeaderPlugin } from '../../src/header/scripts/Header';
import { IframePostMessagePlugin, IframeHeightPlugin } from '../../src/iframes/scripts/iframeHeight';
import { ImageMosaicPlugin } from '../../src/image/scripts/ImageMosaic';
import { InstagramPlugin } from '../../src/social/scripts/Instagram';
import { LanguageSelectorPlugin } from '../../src/header/scripts/LanguageSelector';
import { LargeNavPlugin } from '../../src/header/scripts/LargeNav';
import { LatestArticlesPlugin } from '../../src/article/scripts/LatestArticles';
import { LatestExecutiveBlogPlugin } from '../../src/exec-blog/scripts/LatestExecutiveBlog';
import { LatestPressReleasesPlugin } from '../../src/press-release/scripts/LatestPressReleases';
import { LoadingWheelPlugin } from '../../src/loading-wheel/scripts/LoadingWheel';
import { MobileNavPlugin } from '../../src/header/scripts/MobileNav';
import { ModalNoticePlugin } from '../../src/modal-notice/scripts/ModalNotice';
import { MultiDropdownFilterPlugin } from '../../src/filters/scripts/MultiDropdownFilter';
import { NewsListingPlugin } from '../../src/news/scripts/NewsListing';
import { ObjectFitCoverPlugin } from '../../src/common/scripts/ObjectFitCover';
import { OnClickOrEnterPlugin } from '../../src/common/scripts/OnClickOrEnter';
import { PaginatorPlugin } from '../../src/common/scripts/Paginator';
import { PapProductListingPlugin } from '../../src/product/scripts/PapProductListing';
import { ParallaxHeroPlugin } from '../../src/layout/scripts/ParallaxHero';
import { PipelinePagePlugin } from '../../src/pipeline/scripts/PipelinePage';
import { ProductListingPlugin } from '../../src/product/scripts/ProductListing';
import { PublicationListingPlugin } from '../../src/publication/scripts/PublicationListing';
import { SafetyNoticePlugin } from '../../src/safety-notice/scripts/SafetyNotice';
import { SearchFilterPlugin } from '../../src/filters/scripts/SearchFilter';
import { SearchPagePlugin } from '../../src/search/scripts/SearchPage';
import { StickySidebar } from '../../src/layout/scripts/StickySidebar';
import { SubNavPlugin } from '../../src/sub-nav/scripts/SubNav';
import { TablePlugin } from '../../src/table/scripts/Table';
import { TabsPlugin } from '../../src/tabs/scripts/Tabs';
import { ToggleFilterPlugin } from '../../src/filters/scripts/ToggleFilter';
import { TrackingModalPlugin } from '../../src/tracking-modal/scripts/TrackingModal';
import { TwitterPlugin } from '../../src/social/scripts/Twitter';
import { VideoPlayerPlugin } from '../../src/video-player/scripts/VideoPlayer';
import { WrappingTablePlugin } from '../../src/table/scripts/WrappingTable';
import { ImageScrollCarouselPlugin } from '../../src/image/scripts/ImageScrollCarousel';
import { CorporateHomepagePlugin } from '../../src/homepage/scripts/CorporateHomepage';
import { PageAlerts } from '../../src/page-alerts/scripts/PageAlerts';
import { PressReleaseListingPlugin } from '../../src/news/scripts/PressReleaseListing';

// Components
$.fn.accordion = AccordionPlugin;
$.fn.backLink = BackLinkPlugin;
$.fn.cookieConsent = CorporateCookieConsentPlugin;
$.fn.countrySelector = CountrySelectorPlugin;
$.fn.dateFormatter = DateFormatterPlugin;
$.fn.dropdown = DropdownPlugin;
$.fn.dropdownFilter = DropdownFilterPlugin;
$.fn.eventsGrid = EventsGridPlugin;
$.fn.header = HeaderPlugin;
$.fn.iframeHeight = IframeHeightPlugin;
$.fn.iframePostMessage = IframePostMessagePlugin;
$.fn.imageMosaic = ImageMosaicPlugin;
$.fn.instagram = InstagramPlugin;
$.fn.languageSelector = LanguageSelectorPlugin;
$.fn.largeNav = LargeNavPlugin;
$.fn.loadingWheel = LoadingWheelPlugin;
$.fn.mobileNav = MobileNavPlugin;
$.fn.modalNotice = ModalNoticePlugin;
$.fn.multiDropdownFilter = MultiDropdownFilterPlugin;
$.fn.newsListing = NewsListingPlugin;
$.fn.objectFitCover = ObjectFitCoverPlugin;
$.fn.onClickOrEnter = OnClickOrEnterPlugin;
$.fn.paginator = PaginatorPlugin;
$.fn.parallaxHero = ParallaxHeroPlugin;
$.fn.searchFilter = SearchFilterPlugin;
$.fn.subNav = SubNavPlugin;
$.fn.table = TablePlugin;
$.fn.tabs = TabsPlugin;
$.fn.toggleFilter = ToggleFilterPlugin;
$.fn.trackingModal = TrackingModalPlugin;
$.fn.twitter = TwitterPlugin;
$.fn.videoPlayer = VideoPlayerPlugin;
$.fn.wrappingTable = WrappingTablePlugin;
$.fn.imageScrollCarousel = ImageScrollCarouselPlugin;
$.fn.safetyNotice = SafetyNoticePlugin;
window.PageAlerts = PageAlerts;
$.fn.pressReleaseListing = PressReleaseListingPlugin;

// // Pages
$.fn.cmsForm = CmsFormPlugin;
$.fn.eventListing = EventListingPlugin;
$.fn.homepage = CorporateHomepagePlugin;
$.fn.latestArticles = LatestArticlesPlugin;
$.fn.latestExecutiveBlog = LatestExecutiveBlogPlugin;
$.fn.latestPressReleases = LatestPressReleasesPlugin;
$.fn.papProductListing = PapProductListingPlugin;
$.fn.pipelinePage = PipelinePagePlugin;
$.fn.productListing = ProductListingPlugin;
$.fn.publicationListing = PublicationListingPlugin;
$.fn.searchPage = SearchPagePlugin;
window.basePage = CorporateBasePage;
window.stickySidebar = StickySidebar;
